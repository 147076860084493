import React from 'react'
// import moment from 'moment'
import styled from 'styled-components'
import { useAppData } from '../providers/appData'

import Artist from '../images/artist.jpg'
import Camera from '../images/video-camera.png'

const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  padding: 80px 0; 
  color: #263a81;

  h4 {
    margin: 0;
  }

  .host {
    font-weight: normal;
  }

  .info {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 80%;
    margin: 0 auto 50px auto;
  }
  .info .event {
    flex-grow: 1;
    margin: 0 20px;
  }
  .event .name {
    font-weight: bold;
  }

  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 5px;

  }

  .cta {
    color: #ccc;
    display: flex;
    font-weight: bold;
    justify-content: space-around;
    max-width: 80%;
    margin: auto auto;
    align-items: center;
    background-color: #1a2b57;
    padding: 20px 0;
    border-radius: 10px;
  }

  .cta-icon {
    display: flex;
    flex-direction: column;
  }

  .icon {
    background-color: #263a81;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
  }

  .icon img {
    object-fit: contain;
    width: 35px;
    height: 35px;
    margin: auto auto;
  }

  .label {
    background-color: #d3252f;
    padding: 2px 2px;
    text-align: center;
    font-size: 10px;
    border-radius: 3px;
    margin-top: -3px;
  }

  button {
    background-color: #d3252f;
    border-radius: 5px;
    border: none;
    padding: 10px 10px;
    margin: 0;
    color: white;
    cursor: pointer;
    outline: none;
  }

  button:hover {
    opacity: 0.8;
  }

  .loading-bar {
    background-color: lightgrey;
    height: 10px;
    border-radius: 3px;
  }

  .loading-bar .filled {
    background-color: #d3252f;
    border-radius: 3px;
    width: 66%;
    height: 10px;
  }

  @media (max-width: 900px) {
    .info {
      text-align: center;
      flex-direction: column;
      align-items: center;
    }

    .info h4 {
      margin: 10px 0;
    }

    .cta {
      flex-direction: column;
      width: 200px;
      border-radius: 20px;
    }
    .cta .text {
      display: none;
    }

    .cta button {
      margin-top: 30px;
      outline: none;
      cursor: pointer;
    }
    .cta button:hover {
      opacity: 0.8;
    }
  }
`

const EventPreview = () => {
  const [appDataState, setAppDataState] = useAppData();
  const event = {
    image: '',
    name: 'DEMO EVENT TITLE',
    host: 'Example Company',
    time: new Date('30 September'),
    duration: 60,
  }

  const onEventClick = () => {
    if (appDataState.user) {
      window.location.href = '/sessions';
    } else {
      setAppDataState({
        ...appDataState,
        isModalOpen: true,
      })

    }
  }
  // const [isLive, setIsLive] = useState(false)
  // const [countdown, setCountdown] = useState(0)


  return (
    <Container>
      <div className="info">
        <img src={Artist} alt="artist" />
        <div className="event">
          <h4 className="name">
            {event.name}
          </h4>
          <h4 className="host">
            Hosted by {event.host}
          </h4>
        </div>

        <div className="time-left">
          <div className="counter">
            Time Left: 12h33m
          </div>
          <div className="loading-bar">
            <div className="filled" />
          </div>
        </div>

      </div>
      <div className="cta">
        <div className="cta-icon">
          <div className="icon">
            <img src={Camera} alt="camera icon" />
          </div>
          <div className="label">
            LIVE
          </div>
        </div>
        <div className="text">
          The event is now live on the main stage
        </div>
        <button onClick={onEventClick}>
          ENTER STAGE
        </button>
      </div>

    </Container>
  )
}

export default EventPreview;