import React from "react"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import EventPreview from '../components/eventPreview'
import EventSchedule from '../components/eventSchedule'
import InstagramFeed from '../components/instagramFeed'
import Subscribe from '../components/subscribe'
import CrossfadeHeader from '../components/crossfadeHeader'


import GearIcon from '../images/icon-gear.png';
import ShieldIcon from '../images/icon-shield.png'
import SoundIcon from '../images/icon-sound.png'


import IconBackground from '../images/icon-background.png'

import Header1 from '../images/header-1.jpg'
import Header2 from '../images/header-2.jpg'
import Header3 from '../images/header-3.jpg'
import Header4 from '../images/header-4.jpg'
import Header5 from '../images/header-5.jpg'

const images = [
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
]

const Features = styled.div`
  background: url('${IconBackground}');
  background-size: cover;
  color: white;
  width: 100%;
  padding: 50px 0px;
  text-align: center;

  h2 {
    font-weight: normal;
  }

  .content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 50px;
    padding: 30px 0;
  }

  .feature {
    min-width: 230px;
    width: 250px;
  }

  img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
`

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Concertata" />
  
      <CrossfadeHeader images={images} />
  
      <Features>
        <h2>
          CONCERTATA® is the online event solution <br/>
          you've been looking for
        </h2>
        <div className="content">
          <div className="feature">
            <img src={GearIcon} alt="gear"/>
            <p>
              Providing a digital platform utilizing latest technology to carry out the framework of a traditional event
            </p>
          </div>
          <div className="feature">
            <img src={ShieldIcon} alt="shield" />
            <p>
              A secure & flexible broadcast platform with low latency & redundancy
            </p>
          </div>
          <div className="feature">
            <img src={SoundIcon} alt="sound" />
            <p>
              The perfect solution for artists & businesses alike, for maximum event exposure & professionalism
            </p>
          </div>
        </div>
      </Features>
  
      <EventPreview/>
  
      <EventSchedule />
  
      <div className="section"
        style={{
          backgroundColor: 'black',
          padding: '20px 5px',
          display: 'flex',
          justifyContent: 'center',
          zIndex: '100'
        }}
      >
        <iframe
          src="https://player.vimeo.com/video/449474250?muted=1&autoplay=1"
          title="Concertata presentation"
          width="100%"
          height="600"
          frameBorder="0"
          allow="autoplay; fullscreen"
        ></iframe>
  
      </div>
  
      <InstagramFeed />
  
      <Subscribe />
  
    </Layout>
  )

}


export default IndexPage