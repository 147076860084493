import React, {useState, useEffect}  from 'react'
import styled from 'styled-components'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useAppData } from '../providers/appData'

import ShowBackground from '../images/event-background.png'
import Example1 from '../images/event-1.png'
import Example2 from '../images/event-2.png'
import Example3 from '../images/event-3.png'

import ArrowIcon from '../images/arrow-icon.svg'

import useWindowSize from '../hooks/useWidowSize'

const Container = styled.div`
  padding: 50px 0;
  background: url('${ShowBackground}');
  background-size: cover;

  h1 {
    color: white;
    text-align: center;
  }

  .carousel-provider {
    padding-bottom: 30px;
  }

  .carousel .button {
    position: absolute;
    background: none;
    border: none;
    outline: none;
  }

  .button:hover img {
    opacity: 1;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
  }

  .inverted {
    transform: rotate(180deg)
  }

  .button img {
    opacity: 0.9;
    padding: 5px;
    width: 30px;
    height: 30px;
    outline: none;
  }

  .slider {
    ul {
      overflow-y: hidden;
      height: 460px;
    }
  }

  .schedule {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin: auto auto;
    flex-wrap: wrap;

  }

  .schedule .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: white;
    /* padding-bottom: 20px; */
    box-sizing: border-box;
    max-width: 260px;
    text-align: center;
    height: 100%;
    margin: 0 10px;
    /* height: 460px; */
  }

  .item img {
    /* width: 250px; */
    width: 100%;
    height: 180px;
    padding-bottom: 40px;
  }

  .item h2, .item h3, .item p {
    max-width: 80%;
  }

  h3, h4, .schedule-type {
    color: #d3252f;
  }

  .schedule-type {
    font-weight: bold;
  }

  .item p {
    color: black;
    margin-bottom: 0;
  }

  .item button {
    border: none;
    background-color: #d3252f;
    color: white;
    padding: 10px 10px;
    border-radius: 5px;
    margin: 10px 0;
    cursor: pointer;
    letter-spacing: 1px;
    margin: 20px 0;
  }

  .item .small-arrow {
    width: 12px;
    height: 12px;
    display: inline-block;
    padding: 0;
    margin-left: 5px;
    margin-bottom: -1px;

  }

  .item button:hover {
    opacity: 0.8;
  }

  .button {
    margin-top: -220px;
    margin-left: 10px;
  }

  .button-next {
    right: 0;
    margin-right: 10px;
  }

  .slide {
    height: 480px;
  }

  @media (max-width: 900px) {
    .slide {
      height: 800px;
    }
  }

  .cta {
    margin-top: 30px;
    display: block;
    margin: auto auto;
    color: #d3252f;
    background-color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
  }
  .cta:hover {
    opacity: 0.9;
  }
`

let schedule = Array.from({length: 9}).map(
  (item, index) => {
    return {
      name: `EXAMPLE #${index + 1}`,
      description: 'Brief event description here as a teaser (event name, time, other key aspects).',
      link: 'link',
      img: index % 3 === 0
        ? Example1
        : index % 3 === 1
          ? Example2
          : Example3
    }
  }
)

const EventSchedule = () => {
  const [numSlides, setNumSlides] = useState(0);
  const [scheduleGroups, setScheduleGroups] = useState([])
  const windowSize = useWindowSize();
  const [appDataState, setAppDataState] = useAppData();

  const createScheduleGroups = (itemsPerGroup) => {
    const groups = [];
    const items = [];

    //fill items
    for (const item of schedule) {
      items.push(item)
    }

    //fill groups
    while (items.length > itemsPerGroup) {
      groups.push(items.splice(0, itemsPerGroup))
    }
    groups.push(items.splice(0))
    setScheduleGroups(groups);
  }

  useEffect(() => {
    const width = windowSize.width;

    if (width >= 1250) {
      if (numSlides !== 3) {
       setNumSlides(3);
       createScheduleGroups(3);
      }
    } else if (width > 950) {
      if (numSlides !== 2) {
        setNumSlides(2);
        createScheduleGroups(2)
      }
    } else {
      if (numSlides !== 1) {
        setNumSlides(1);
        createScheduleGroups(1);
      }
    }
  }, [numSlides, windowSize])

  const onEventClick = () => {
    if (appDataState.user) {
      window.location.href = '/sessions';
    } else {
      setAppDataState({
        ...appDataState,
        isModalOpen: true,
      })

    }
  }

  return (
    <Container>
      <h1>
        Schedule
      </h1>

      <CarouselProvider
        className="carousel-provider"
        totalSlides={numSlides}
      >
        <Slider className="slider">
          {
            scheduleGroups.map(
              (group, index) => <Slide key={index} className="slide">
                <div className="schedule">
                  {
                    group.map((item, index) => <div key={index} className="item">
                      <img src={item.img} alt={item.name} />
                      <div className="schedule-type">FEATURED</div>
                      <h3>{item.name}</h3>
                      <p>
                        {item.description}
                      </p>
                      <button onClick={onEventClick}>
                        ATTEND EVENT
                        <img src={ArrowIcon} className="small-arrow" alt="arrow" />
                      </button>
                    </div>)
                  }
                </div>
              </Slide>
            )
          }
        </Slider>
        <ButtonBack className="button button-back"> <img src={ArrowIcon} className="inverted" alt="arrow left" /> </ButtonBack>
        <ButtonNext className="button button-next"> <img src={ArrowIcon} alt="arrow right" /> </ButtonNext>
      </CarouselProvider>


      <button className="cta">
        VIEW FULL SCHEDULE
      </button>

    </Container>
  )
}

export default EventSchedule