import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import { useAppData } from '../providers/appData'

const Container = styled.div`
  position: relative;
  height: 50vw;
  min-height: 650px;
  width: 100%;
  color: white;
  box-sizing: border-box;
  border: none;
  text-shadow: 1px 1px black;

  .content {
    position: absolute;
    flex-direction: column;
    align-content: center;
    color: white;
    padding-left: 5%;
    padding-top: 80px;
  }

  .intro {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 400;
    font-size: 2em;
  }

  h1 {
    font-weight: bold;
  }

  p {
    line-height: 28px;
    font-size: 1.2em;
  }

  p, .intro {
    opacity: 0.9;
  }

  .subtext {
    font-weight: bold;
    padding: 5px 0;
    font-size: 1.5em
  }

  button {
    background-color: unset;
    border: 2px white solid;
    border-radius: 10px;
    color: white;
    padding: 10px 15px;
    margin-top: 30px;
    cursor: pointer;
    font-weight: bold;
    outline: none;
  }

  button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  img {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    filter: brightness(60%);
    transition: ${
      props => `opacity ${props.transition}s`
    }
  }

  img:nth-child(2) {
    opacity: 0;
  }
`;

const CrossfadeHeader = ({images}) => {
  const img1Ref = useRef();
  const img2Ref = useRef();
  const [appDataState, setAppDataState] = useAppData();
  const REFRESH_INTERVAL = 10000;
  const REFRESH_TRANSITION = 3;

  const fadeImg = (index, imgToggle) => {
    const nextImg = (index + 1) % images.length;
    const nextImgToggle = !imgToggle;

    try {
      const img1 = window.document.querySelector('#crossfade-1');
      const img2 = window.document.querySelector('#crossfade-2');
  
      if (imgToggle) {
        img2.src = images[nextImg];
        img1.style.opacity = '0.0';
        img2.style.opacity = '1.0';
      } else {
        img1.src = images[nextImg];
        img1.style.opacity = '1.0';
        img2.style.opacity = '0.0';
      }
        
      setTimeout(() => { fadeImg(nextImg, nextImgToggle)}, REFRESH_INTERVAL)

    } catch (e) {
      console.error(e)
      setTimeout(() => { fadeImg(nextImg, nextImgToggle)}, REFRESH_INTERVAL)
    }
  }

  useEffect(() => {
    setTimeout(
      () => {
        fadeImg(0, true)
      },
      REFRESH_INTERVAL
    )
  })

  const onDemoClick = () => {
    if (appDataState.user) {
      window.location.href = '/sessions';
    } else {
      setAppDataState({
        ...appDataState,
        isModalOpen: true,
      })
    }
  }

  return (
    <Container transition={REFRESH_TRANSITION}>
      <img id="crossfade-1" ref={img1Ref} alt="background 1" src={images[0]} />
      <img id="crossfade-2" ref={img2Ref} alt=""             src={images[1]}/>
      <div className="content">
        <h3 className="intro">
          Proudly Announcing
        </h3>
        <h1>
          OSA's CONCERTATA© <br/>
          for Virtual Summits
        </h1>
        <p>
          From impactful corporate presentations to <br/>
          captivating workshops to online summits, <br/>
          deliver your best performance with even <br/>
          more convenience than a live event! <br/>
        </p>
        <button onClick={onDemoClick}>
          GO TO DEMO
        </button>
      </div>
    </Container>
  )
}

export default CrossfadeHeader