import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

const Container = styled.div`
  padding: 30px 0;

  h1 {
    color: #263a81;
    text-align: center;
    margin-bottom: 40px;

  }

  .feed {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }

  a {
    margin: 10px;
  }

  .gatsby-image-wrapper:hover {
    opacity: 0.75;
  }
`

const InstagramFeed = () => {

  return (
    <StaticQuery
      query={graphql`
        query {
          allInstaNode(
            sort: {
              fields: [timestamp]
              order: DESC
            }
            limit: 3
          ) {
            edges {
              node {
                id
                caption
                timestamp
                localFile {
                  childImageSharp {
                    fixed(width: 300, height: 300) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Container>
          <h1>
            Recent Happenings
          </h1>
          <div className="feed">
            {
              data.allInstaNode.edges.map(
                ({node}, index) => <a key={index} href={`https://instagram.com/p/${node.id}`} target="_blank" rel="noopener noreferrer" title={node.caption}>
                  <Img fixed={node.localFile.childImageSharp.fixed} />
                </a>
              )
            }
          </div>
        </Container>
      )}
    />
  )
}

export default InstagramFeed;